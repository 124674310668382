import { cva } from "class-variance-authority";
import React, { FC, ReactElement, useMemo } from "react";
import useViewModuleAccess from "~/hooks/access/useViewModuleAccess";
import { AccessModule } from "~/types/general.types";
import { Link, usePathname } from "~/i18n/routing";

interface SidebarNavigationItemProps {
    icon: ReactElement;
    label: string;
    href: string;
    exact?: boolean;
    variant?: "default" | "danger";
    collapsed?: boolean;
    module?: AccessModule;
}

const itemStyles = cva("border-none text-sm rounded-md items-center flex p-2 gap-2 transition-colors cursor-pointer", {
    variants: {
        active: {
            true: "",
            false: "text-gray-800",
        },
        variant: {
            default: null,
            danger: "md:hover:bg-red-50 md:hover:text-red-700 md:focus:bg-red-50 md:focus:text-red-700",
        },
        collapsed: {
            true: "aspect-square w-9",
        },
    },
    compoundVariants: [
        {
            active: true,
            variant: "default",
            className: "bg-primary text-primary-foreground",
        },
        {
            active: false,
            variant: "default",
            className: "md:hover:bg-gray-100 md:focus:bg-gray-100",
        },
    ],
});

const SidebarNavigationItem: FC<SidebarNavigationItemProps> = ({
    collapsed = false,
    exact = false,
    href,
    icon,
    label,
    module,
    variant = "default",
}) => {
    const pathname = usePathname();

    // TODO: rebuild the logic after migration to NextJS 14
    const active = useMemo(() => {
        if (exact) return pathname === href;

        const currentPathSegments = pathname.split("/").filter(Boolean);
        const hrefSegments = href.split("/").filter(Boolean);

        return hrefSegments[0] === currentPathSegments[0];
    }, [exact, href, pathname]);
    const { loading, allowed } = useViewModuleAccess(module);

    if (loading || !allowed) return null;

    return (
        <Link href={href} className={itemStyles({ active, variant, collapsed })} title={label}>
            {React.cloneElement(icon, { className: "h-5" })}
            {!collapsed && <span>{label}</span>}
        </Link>
    );
};

export default SidebarNavigationItem;
