import useBreakpoint from "./useBreakpoint";
import { useEffect, useState } from "react";
import { PlatformType } from "~/types/general.types";

interface EnvironmentInfo {
    isMobile: boolean;
    platform: PlatformType;
}

const useEnvironmentInfo = (): EnvironmentInfo => {
    const breakpoint = useBreakpoint();
    const [platform, setPlatform] = useState<PlatformType>(null);

    useEffect(() => {
        if (!window) return;

        // For browsers that doesn't support the new userAgentData solution below
        if ("platform" in window.navigator) {
            const navigatorPlatform = window.navigator.platform.toLowerCase();

            if (navigatorPlatform.includes("mac")) setPlatform("mac");
            if (navigatorPlatform.includes("win")) setPlatform("windows");
            if (navigatorPlatform.includes("linux")) setPlatform("linux");
        }

        // For browsers that has removed the old navigator.platform solution above
        if ("userAgentData" in window.navigator) {
            const uaPlatform: string = (window.navigator.userAgentData as any).platform.toLowerCase();

            if (uaPlatform.includes("mac")) setPlatform("mac");
            if (uaPlatform.includes("win")) setPlatform("windows");
            if (uaPlatform.includes("linux")) setPlatform("linux");
        }
    }, []);

    return {
        isMobile: ["xs", "sm"].includes(breakpoint),
        platform,
    };
};

export default useEnvironmentInfo;
