import { useEffect, useState } from "react";
import { Breakpoint } from "~/types/general.types";

type BreakpointDefinition = { name: Breakpoint; minWidth: number };

const breakpoints: BreakpointDefinition[] = [
    { name: "2xl", minWidth: 1536 },
    { name: "xl", minWidth: 1280 },
    { name: "lg", minWidth: 1024 },
    { name: "md", minWidth: 768 },
    { name: "sm", minWidth: 640 },
];

const getBreakpoint = (windowWidth: number): Breakpoint => {
    for (const breakpoint of breakpoints) {
        if (windowWidth >= breakpoint.minWidth) return breakpoint.name;
    }

    return "xs";
};

const useBreakpoint = (): Breakpoint => {
    const [breakpoint, setBreakpoint] = useState<Breakpoint>(() => {
        if (typeof window === "undefined") return "xl";

        return getBreakpoint(window.innerWidth);
    });

    useEffect(() => {
        if (typeof window === "undefined") return;

        const handleResize = () => {
            setBreakpoint(getBreakpoint(window.innerWidth));
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return breakpoint;
};

export default useBreakpoint;
