"use client";

import { FC } from "react";
import useEnvironmentInfo from "~/hooks/useEnvironmentInfo";
import { AdminWebToolbar } from "~/components/admin/toolbar/admin-web-toolbar";
import { AdminDevicesToolbar } from "~/components/admin/toolbar/admin-devices-toolbar";
import { WEB_PLATFORMS } from "~/constants/general.constants";

export const AdminToolbarWrapper: FC = () => {
    const { platform } = useEnvironmentInfo();
    const isWeb = WEB_PLATFORMS.includes(platform);

    if (!isWeb) {
        return <AdminDevicesToolbar />;
    }

    return <AdminWebToolbar />;
};
