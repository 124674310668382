"use client";

import React, { FC, useContext, useMemo, useState } from "react";
import { useTranslations } from "next-intl";
import { useQuery as useReactQuery, useMutation as useReactMutation } from "@tanstack/react-query";
import { Button } from "@ui/button";
import { useSession } from "next-auth/react";
import { LoaderCircleIcon, ChevronsUpDown, X } from "lucide-react";
import { getBasicCustomersList } from "~/app/api/customers-api";
import { BasicCustomersListData, CustomersQueryKeys } from "~/types/customers.types";
import { ID } from "~/types/general.types";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@ui/command";
import { RegularTooltip } from "@ui/tooltip";
import { cn } from "~/utils/lib.utils";
import { UserDataResponse, UserQueryKeys } from "~/types/user.types";
import { UserContext } from "~/context/User.context";
import { updateSimulatedCustomerId } from "~/app/api/user-api";
import useGeneralErrorToast from "~/hooks/useGeneralErrorToast";
import { setUserData } from "~/actions/user.actions";

interface CustomersDropdownProps {
    className?: string;
}

export const CustomersDropdown: FC<CustomersDropdownProps> = ({ className }) => {
    const t = useTranslations();
    const {
        state: { data: userData },
        dispatch,
    } = useContext(UserContext);
    const session = useSession();
    const sessionJwt = session.data?.jwt as string;
    const simulateCustomerId = userData.simulate_customer_id;

    const [isOpened, setIsOpened] = useState(false);

    const { data: customersList } = useReactQuery<BasicCustomersListData>({
        queryKey: [CustomersQueryKeys.BASIC_CUSTOMERS_LIST],
        queryFn: () => getBasicCustomersList(sessionJwt),
    });

    const customerIdMutation = useReactMutation({
        mutationKey: [UserQueryKeys.USER],
        mutationFn: (newCustomerId: ID | null) =>
            updateSimulatedCustomerId({
                userId: userData.id,
                simulateCustomerId: newCustomerId as ID,
                sessionJwt: sessionJwt,
            }),
        onSuccess: (data: UserDataResponse) => {
            dispatch(setUserData({ ...userData, simulate_customer_id: data.simulate_customer_id }));
        },
    });
    useGeneralErrorToast(customerIdMutation.error);

    const currentCustomerLabel = useMemo(() => {
        const customer = customersList?.data.find((customer) => customer.id === simulateCustomerId);

        return customer ? customer.attributes.name : t("customerPlaceholder");
    }, [simulateCustomerId, customersList]);

    const handleOnSelect = (id: ID) => {
        customerIdMutation.mutate(id);
        setIsOpened(false);
    };

    const handleClearCustomerId = () => {
        customerIdMutation.mutate(null);
    };

    return (
        <div className={cn("flex w-60 items-center justify-between", className)}>
            <Popover open={isOpened} onOpenChange={setIsOpened}>
                <PopoverTrigger asChild>
                    <Button
                        variant="ghost"
                        className={cn("w-full px-2", simulateCustomerId && "w-48")}
                        disabled={customerIdMutation.isPending}
                    >
                        {customerIdMutation.isPending && <LoaderCircleIcon className="mr-2 h-4 w-4 animate-spin" />}
                        <RegularTooltip text={simulateCustomerId ? currentCustomerLabel : null}>
                            <div className="flex w-full items-center justify-between">
                                <span className="truncate">{currentCustomerLabel}</span>
                                <ChevronsUpDown className="h-4 w-4" />
                            </div>
                        </RegularTooltip>
                    </Button>
                </PopoverTrigger>

                <PopoverContent sideOffset={15} className="z-admin-toolbar-customers w-72 p-1">
                    <Command>
                        <CommandInput placeholder={t("customerSearchPlaceholder")} />

                        <CommandList>
                            <CommandEmpty>{t("customersEmpty")}</CommandEmpty>
                            <CommandGroup>
                                {customersList?.data.map((customer) => (
                                    <CommandItem
                                        className={cn(
                                            "my-1 cursor-pointer",
                                            customer.id === simulateCustomerId && "bg-primary text-white",
                                        )}
                                        onSelect={() => handleOnSelect(customer.id)}
                                        key={customer.id}
                                    >
                                        <span className="truncate">{customer.attributes.name}</span>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>

            {simulateCustomerId && (
                <Button
                    variant="ghost"
                    size="icon"
                    disabled={customerIdMutation.isPending}
                    onClick={handleClearCustomerId}
                >
                    <X className="h-5 w-5" />
                </Button>
            )}
        </div>
    );
};
