import { usePostHog } from "posthog-js/react";
import React, { FC, useContext } from "react";
import { UserContext } from "~/context/User.context";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@ui/avatar";
import { useRouter } from "next/navigation";
import { signOut } from "next-auth/react";
import { cn } from "~/utils/lib.utils";
import { useTranslations } from "next-intl";

const SidebarUser: FC = () => {
    const { state } = useContext(UserContext);
    const router = useRouter();
    const t = useTranslations();
    const posthog = usePostHog();

    async function handleLogoutClick() {
        posthog.reset();
        await signOut();
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div
                    className={cn(
                        "mt-4 flex cursor-pointer select-none items-center gap-4 rounded",
                        !state.data.collapsedSidebar && "pr-8",
                    )}
                    tabIndex={0}
                >
                    <Avatar>
                        <AvatarImage src={state.data.avatar?.url ?? ""} />
                        <AvatarFallback>{state.data.firstName.substring(0, 1)}</AvatarFallback>
                    </Avatar>

                    {!state.data.collapsedSidebar && (
                        <p className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-primary">
                            {state.data.firstName}
                        </p>
                    )}
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start" side="top" sideOffset={8}>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col gap-1">
                        <p className="text-sm font-medium leading-none">
                            {`${state.data.firstName} ${state.data.lastName}`.trim()}
                        </p>
                        <p className="text-xs leading-none text-muted-foreground">{state.data.email}</p>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => router.push("/profile")}>{t("profile")}</DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleLogoutClick}>{t("logOut")}</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default SidebarUser;
