import { cva } from "class-variance-authority";
import React, { FC, MouseEvent, MouseEventHandler, useMemo } from "react";
import useViewModuleAccess from "../../hooks/access/useViewModuleAccess";
import { AccessModule } from "../../types/general.types";
import { Link, usePathname } from "~/i18n/routing";

interface TabBarNavigationItemProps {
    icon: any;
    exact?: boolean;
    href?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    module?: AccessModule;
}

const itemStyles = cva("border-none text-sm rounded-lg items-center flex gap-3 transition-colors cursor-pointer px-3", {
    variants: {
        active: {
            true: "bg-primary text-white",
            false: "text-primary",
        },
    },
});

const TabBarNavigationItem: FC<TabBarNavigationItemProps> = ({ exact = false, href, icon, module, onClick }) => {
    const pathname = usePathname();
    const active = useMemo(() => {
        if (pathname === href) return true;
        if (exact) return false;

        return !!(href && pathname.startsWith(href));
    }, [exact, href, pathname]);
    const { loading, allowed } = useViewModuleAccess(module);

    if ((!href && !onClick) || loading || !allowed) return null;

    function handleClick(event: MouseEvent<HTMLAnchorElement>) {
        if (!onClick) return;

        event.preventDefault();
        onClick(event);
    }

    return (
        <Link href={href ?? "#"} onClick={handleClick} className={itemStyles({ active })}>
            {React.createElement(icon, { className: "h-6" })}
        </Link>
    );
};

export default TabBarNavigationItem;
