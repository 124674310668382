import { FC, useState } from "react";
import { CustomersDropdown } from "~/components/customer/simulation/customers-dropdown";
import { cn } from "~/utils/lib.utils";
import { ChevronDown } from "lucide-react";
import { ToolbarLinks } from "~/components/admin/toolbar/toolbar-links";

export const AdminDevicesToolbar: FC = () => {
    const [isHidden, setIsHidden] = useState(true);

    return (
        <div
            className={cn(
                "fixed left-1/2 top-0 z-admin-toolbar flex w-max -translate-x-1/2 items-center justify-center divide-x divide-gray-300 rounded-b-lg border bg-white p-1.5 shadow-lg transition-all duration-200",
                isHidden && "p-0",
            )}
        >
            <CustomersDropdown className={cn("px-1.5", isHidden && "hidden")} />
            <ToolbarLinks className={cn("px-1.5", isHidden && "hidden")} />

            <div
                className="absolute -bottom-8 flex h-8 w-24 items-start justify-center !border-0"
                onClick={() => setIsHidden((prevState) => !prevState)}
            >
                <div className="flex w-16 items-center justify-center rounded-b-md bg-white shadow-md">
                    <ChevronDown
                        className={cn("h-3 w-3 transition-transform duration-200", !isHidden && "rotate-180")}
                    />
                </div>
            </div>
        </div>
    );
};
