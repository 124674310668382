import { useEffect } from "react";
import { toast } from "sonner";
import { useTranslations } from "next-intl";

const useGeneralErrorToast = (error: any) => {
    const t = useTranslations();

    useEffect(() => {
        if (!error) return;

        toast.error(t("generalError.heading"), { description: t("generalError.message") });
    }, [error, t]);
};

export default useGeneralErrorToast;
