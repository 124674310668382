const BASE_URL = "/api/customers";

export const getBasicCustomersList = async (sessionJwt: string) => {
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE}${BASE_URL}?fields[0]=id&fields[1]=name&pagination[limit]=99999&sort=name:asc`,
        {
            headers: {
                Authorization: `Bearer ${sessionJwt}`,
            },
        },
    );

    if (!response.ok) {
        throw new Error("Failed to fetch customers");
    }

    return await response.json();
};
