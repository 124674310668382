import { NextAuthOptions } from "next-auth";
import CredentialsProvider from "next-auth/providers/credentials";

export const authOptions: NextAuthOptions = {
    providers: [
        CredentialsProvider({
            name: "e-mail",
            credentials: {
                email: { label: "E-mail", type: "email" },
                password: { label: "Password", type: "password" },
            },
            async authorize(credentials) {
                if (!credentials) return null;

                try {
                    const res = await fetch(`${process.env.STRAPI_URL}/api/auth/local`, {
                        method: "POST",
                        body: JSON.stringify({ identifier: credentials.email, password: credentials.password }),
                        headers: { "Content-Type": "application/json" },
                    });

                    const { user, jwt } = await res.json();

                    if (res.ok && user) {
                        return { ...user, jwt };
                    }

                    return null;
                } catch (e) {
                    return null;
                }
            },
        }),
    ],
    callbacks: {
        session: async ({ session, token }) => {
            session.id = token.id as number;
            session.jwt = token.jwt as string;

            return session;
        },
        jwt: async ({ token, user }) => {
            if (!!user) {
                token.id = user.id;
                token.jwt = user.jwt;
            }

            return token;
        },
    },
    pages: {
        signIn: "/login",
    },
};
